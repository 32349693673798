import { injectable } from 'inversify';

import { FileInputValue } from '@vk-hr-tek/ui/input';

import { FormAttributesRequest } from '../types';

type Document = Record<string, string | FileInputValue | FileInputValue[]>;

@injectable()
export class EventsAttributesMapper {
  formatAttributes(
    submittedValues: Record<
      string,
      string | FileInputValue | FileInputValue[] | Document[]
    >,
  ) {
    const formattedValues: FormAttributesRequest = {};
    const values: Record<
      string,
      string | FileInputValue | FileInputValue[] | Document[]
    > = {};

    for (const key in submittedValues) {
      if (
        key.startsWith('_attribute_mandatory_') ||
        key.startsWith('_attribute_optional_')
      ) {
        const correctKey = key
          .replace('_mandatory', '')
          .replace('_optional', '');

        values[correctKey] = [
          ...((values[correctKey] as FileInputValue[]) || []),
          ...(submittedValues[key] as FileInputValue[]),
        ];
      } else {
        values[key] = submittedValues[key];
      }
    }

    for (const key in values) {
      if (key.startsWith('document_')) {
        if (Array.isArray(values[key])) {
          const documents = values[key] as Document[];

          const attributeIds = new Set<string>();

          documents.forEach((document) => {
            Object.keys(document).forEach((attributeId) =>
              attributeIds.add(attributeId),
            );
          });

          attributeIds.forEach((attribute) => {
            formattedValues[attribute] = documents.map((document) => {
              if (
                document[attribute] &&
                typeof document[attribute] !== 'string'
              ) {
                if (Array.isArray(document[attribute])) {
                  return (document[attribute] as FileInputValue[]).map(
                    (value) => value?.value,
                  );
                } else {
                  return (document[attribute] as FileInputValue).value;
                }
              } else if (
                document[attribute] &&
                (typeof document[attribute] === 'string' ||
                  typeof document[attribute] === 'number')
              ) {
                return document[attribute].toString();
              } else {
                return '';
              }
            });
          });
        }
      } else if (values[key] && typeof values[key] !== 'string') {
        if (Array.isArray(values[key])) {
          formattedValues[key] = (values[key] as FileInputValue[]).map(
            (value) => {
              if (typeof value !== 'object') {
                return value;
              }

              if (
                'goal' in value ||
                'goals' in value ||
                'share' in value ||
                'new_share' in value
              ) {
                return value as any;
              }
              return value?.value;
            },
          );
        } else if (
          values[key] !== 'string' &&
          'goals' in (values[key] as any)
        ) {
          const item = values[key] as any;
          formattedValues[key] = item;
        } else {
          formattedValues[key] = (values[key] as FileInputValue).value;
        }
      } else if (
        values[key] &&
        (typeof values[key] === 'string' || typeof values[key] === 'number')
      ) {
        formattedValues[key] = values[key].toString();
      }
    }

    return formattedValues;
  }
}
