/* eslint-disable @typescript-eslint/no-explicit-any */
import { injectable } from 'inversify';
import { validateOrReject, validate } from 'class-validator';
import { plainToClass } from 'class-transformer';
import { at } from 'lodash';

import { FileType } from '../file-type';
import { AppError } from '../error';

type AllStrings<T> = { [P in keyof T]?: string };

type NumberValidator = {
  type: 'number';
  less_than?: number;
  less_than_or_equal?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  attribute_id: string;
};
interface FileInputValue {
  value: string | File;
  source: string | File;
}

const NUMBER_B_IN_MB = 1048576;
const DEFAULT_SIZE_LIMIT = 20;

const validateFile = async (
  file: File,
  message: string,
  fileTypes: string[],
  fileTypeChecker: FileType,
) => {
  const fileType = file?.name.includes('.sig')
    ? 'application/pgp-signature'
    : await fileTypeChecker.fileTypeFromBlob(file);

  return !fileTypes.includes(fileType) ||
    file.size > DEFAULT_SIZE_LIMIT * NUMBER_B_IN_MB
    ? message
    : undefined;
};

@injectable()
export class ValidationService {
  constructor(private readonly fileType: FileType) {}

  /** Проверить объект на соответствие валидационной схеме и в случае несоответствия выбросить исключение
   * @param {Object.<string, any>} dto Валидируемый объект
   * @param {new () => Object.<string, any>} [constructor] Класс, описывающий валидационную схему
   * @param {string[]} [groups] Массив групп, которые встречаются в валидационной схеме
   * @returns {Promise<void>}
   *  */
  async validateOrReject(
    dto: Record<string, any>,
    constructor?: new () => Record<string, any>,
    groups?: string[],
  ): Promise<void> {
    try {
      const value = constructor ? plainToClass(constructor, dto) : dto;

      await validateOrReject(value, { groups });
    } catch (err) {
      throw new AppError('client', {
        code: 400,
        message: 'Bad Request',
        error: 'Bad Request',
      });
    }
  }

  /** Проверить объект на соответствие валидационной схеме и вернуть объект с ошибками
   * @template {class} T
   * @param {Object.<string, any>} dto Валидируемый объект
   * @param {new () => T} [constructor] Класс, описывающий валидационную схему
   * @param {string[]} [groups] Массив групп, которые встречаются в валидационной схеме
   * @returns {Promise<Object>}
   *  */
  async validate<T extends Record<string, any>>(
    dto: Record<string, any>,
    constructor?: new () => T,
    groups?: string[],
  ): Promise<AllStrings<T>> {
    const result: AllStrings<T> = {};

    const value = constructor ? plainToClass(constructor, dto) : dto;

    const errors = await validate(value, {
      validationError: { target: false, value: false },
      groups,
    });

    if (errors && errors.length) {
      errors.forEach(({ property, constraints }) => {
        result[property as keyof T] = constraints
          ? constraints[Object.keys(constraints)[0]]
          : 'Value is invalid';
      });
    }

    return result;
  }

  /** Создать функцию, которая в случае "пустого" значения выведет заданное сообщение об ошибке
   * @param {string} [message=Обязательное поле] Сообщение об ошибке в случае, если значение "пустое"
   * @returns {(value: any) => string | undefined}
   * */
  required =
    (message = 'Обязательное поле') =>
    (value: any) =>
      typeof value === 'undefined' ||
      value === null ||
      (typeof value === 'boolean' && value === false) ||
      (typeof value === 'string' && value.trim() === '') ||
      (Array.isArray(value) && value.length === 0)
        ? message
        : undefined;

  /** Определить является ли текущее валидируемое поле обязательным
   * Текущее валидируемое поле зависит от другого поля (определено в options.field).
   * Если значение поля field (из options.field) не присутствует в in (из options.in),
   * то текущее валидируемое поле является необязательным, иначе обязательным.
   * @template T
   * @param {string} [message=Обязательное поле] Сообщение об ошибке
   * @param [options]
   * @param {string} options.field Имя поля, от которого зависит валидируемое поле
   * @param {T[]} options.in Массив значений для поля field
   * @param {'in' | 'ex'} options.operation Тип операции (include или exclude)
   * @returns {(value: any) => string | undefined}
   * */
  requiredIfNotIn = <T>(
    message = 'Обязательное поле',
    options?: { field: string; in: T[]; operation: 'in' | 'ex' },
  ) =>
    options
      ? (value: any, allValues?: any) => {
          const isIncludes = options.in.includes(
            at(allValues, options.field)[0],
          );

          return allValues &&
            (options.operation === 'ex' ? isIncludes : !isIncludes)
            ? this.required(message)(value)
            : undefined;
        }
      : this.required(message);

  /** Создать функцию, которая будет возвращать предупреждающее сообщение, если заданное строковое значение дублируется
   * @param {string} message Сообщение, указывающее, что значение дублируется
   * @param {{ field: string; notIn: string[] }} options Опции, содержат список для проверки на дубликаты
   * @returns {(value: any) => string | undefined}
   * */
  notInList =
    (
      message = 'Такое название уже существует',
      options: { field: string; notIn: string[] },
    ) =>
    (value: any) => {
      if (typeof value !== 'string') {
        return undefined;
      }

      const sameRole = options.notIn.find(
        (name) => name.toLowerCase() === value.toLowerCase(),
      );

      return sameRole ? message : undefined;
    };

  /**
   * Создать функцию, которая будет возвращать предупреждающее сообщение,
   * если заданное значение не относится к допустимым типам файлов или файл превышает допустимый размер
   * @param {string} message Сообщение, указывающее, что значение имеет недопустимый тип или размер
   * @param {{ field: string; fileTypes: string[] }} options Опции, содержат список допустимых типов
   * @returns {(value: any) => Promise<string | undefined>}
   * */
  requiredIfFile = (
    message = 'Обязательное поле',
    options: { field: string; fileTypes: string[] },
  ) => {
    const fileTypeChecker = this.fileType;

    return async function validateValue(
      value: any,
    ): Promise<string | undefined> {
      if (
        !value ||
        typeof value === 'string' ||
        typeof (value as FileInputValue)?.source === 'string'
      )
        return undefined;

      if (Array.isArray(value)) {
        const res = await value.reduce((error: string | undefined, arrItem) => {
          return error || validateValue(arrItem);
        }, undefined);
        return res;
      } else {
        const result = await validateFile(
          ((value as FileInputValue)?.source as File) || (value as File),
          message,
          options.fileTypes,
          fileTypeChecker,
        );
        return result;
      }
    };
  };

  maxValue = (max: number, message: string) => (value: any) =>
    Number(value) > Number(max) ? message : undefined;

  minValue = (min: number, message: string) => (value: any) =>
    value <= Number(min) ? message : undefined;

  /** Определить попадает ли значение в заданный диапазон
   * @param {string} [min=Обязательное поле] Минимальное значение диапазона
   * @param {string} [max=Обязательное поле] Максимальное значение диапазона
   * @param {string} [message=Обязательное поле] Сообщение об ошибке в случае, если значение не попадает в заданный диапазон
   * @returns {(value: any) => string | undefined}
   * */
  rangeValue = (min: number, max: number, message: string) => (value: any) =>
    Number(value) < min || Number(value) > max ? message : undefined;

  /** Создать функуию, которая будет возвращать предупреждающее сообщение, если переданное значение превышает указанный в опциях лимит
   * @param {string} message Сообщение, указывающее, что значение превышает лимит
   * @param {{ field: string; sizeLimit: number }} options Опции, содержат лимит
   * */
  sizeLimit = (
    message = 'Объем файла слишком большой',
    options: { field: string; sizeLimit: number },
  ) =>
    function validateValue(
      value: Record<string, any>,
      allValues: Record<string, any>,
    ): string | undefined {
      const sizeLimit = options.sizeLimit * NUMBER_B_IN_MB;

      if (
        !value ||
        typeof value === 'string' ||
        typeof (value as FileInputValue)?.source === 'string'
      )
        return undefined;

      if (Array.isArray(value)) {
        const res = value.reduce((error: string | undefined, arrItem) => {
          return error || validateValue(arrItem, allValues);
        }, undefined);
        return res;
      } else {
        return at(allValues, options.field)[0] && value.source?.size > sizeLimit
          ? message
          : undefined;
      }
    };

  /** Создать функуию, которая будет возвращать предупреждающее сообщение, если переданное значение превышает указанный в опциях лимит
   * @param {{ field: string; sizeLimit: number, attributeId: string, validator?: NumberValidator }} options Опции, содержат attributeId (id поля) и опционально числовой валидатор
   * */
  moreOrLess =
    (options: {
      field: string;
      attributeId: string;
      validator?: NumberValidator;
    }) =>
    (value?: string | undefined) => {
      if (!value || !options.validator) {
        return 'Обязательное поле';
      }

      const isNumber = /^\d+(\.\d+)?$/.test(value);

      const numberWithPoint = !isNaN(+value) && String(value).includes('.');

      if (!isNumber && !numberWithPoint) {
        return 'Данные должны быть числовым значением';
      }

      if (options.validator.greater_than) {
        const numberValue = Number(value);
        return numberValue > options.validator.greater_than
          ? undefined
          : `Число должно быть больше чем ${options.validator.greater_than}`;
      }

      if (options.validator.greater_than_or_equal) {
        const numberValue = Number(value);
        return numberValue >= options.validator.greater_than_or_equal
          ? undefined
          : `Число должно быть больше или равно ${options.validator.greater_than_or_equal}`;
      }

      if (options.validator.less_than) {
        const numberValue = Number(value);
        return numberValue < options.validator.less_than
          ? undefined
          : `Число должно быть меньше чем ${options.validator.less_than}`;
      }

      if (options.validator.less_than_or_equal) {
        const numberValue = Number(value);
        return numberValue <= options.validator.less_than_or_equal
          ? undefined
          : `Число должно быть меньше или равно ${options.validator.less_than_or_equal}`;
      }
    };
}
