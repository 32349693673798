import { injectable } from 'inversify';
import { orderBy, differenceWith, groupBy } from 'lodash';
import { formatISO } from 'date-fns';

import { t } from '@vk-hr-tek/core/translations/t';
import { FormatService } from '@vk-hr-tek/core/format';
import { ChipColorUnion } from '@vk-hr-tek/ui/ColoredChip';

import {
  Event as EventDetailEntity,
  NodeAction,
  FormAttribute,
  Attribute,
  EventNode,
  EventCompetency,
  EventCompetencyGroup,
  EventCompetencyOption,
  CompetencyScaleValue,
  AttributeValueGoalReview,
} from '@app/gen/events';

import {
  EventWithValidators,
  isFileAttribute,
  isFileMultipleAttribute,
  isTextAttribute,
  isMultipleDocumentFileAttribute,
  isMultipleDocumentFileMultipleAttribute,
  isMultipleDocumentTextAttribute,
  ActionInitialValues,
  ActionNotification,
} from '../types';

import { EventsMapper } from './events.mapper';
import { EventsFormAttributesMapper } from './events.form-attributes.mapper';
import { EventsFormMetaMapper } from './events.form-meta.mapper';
import { EventsAttributesMetaMapper } from './events.attributes-meta.mapper';

const signStatuses: Record<string, string> = {
  signed: 'Подписано',
  on_signing: 'На подписании',
  can_sign: 'Подписать',
  not_signed: 'Не подписано',
  declined_sign: 'Отказался',
};

@injectable()
export class EventsDetailMapper {
  constructor(
    private format: FormatService,
    private transform: EventsMapper,
    private transformFormAttributes: EventsFormAttributesMapper,
    private transformFormMeta: EventsFormMetaMapper,
    private transformAttributesMeta: EventsAttributesMetaMapper,
  ) {}

  private processTags(event: EventWithValidators) {
    const myNodeActions = event.nodes.map(({ actions }) => actions).flat();

    const trivioBooking = event.active_nodes.some(
      (activeNode) => activeNode.action.type === 'system_booking_trip_ordering',
    );

    return event.active_nodes.map(
      ({
        action: { name, type },
        document_type_names,
        custom_state,
        responsible,
      }) => {
        return {
          items: [
            custom_state?.name || name,
            responsible || '',
            ...(document_type_names || []),
          ].filter((item) => !!item),
          isActive:
            myNodeActions.some(
              (action) =>
                (action.type === type || action.type === `${type}_batch`) &&
                action.responsible.some((resp) => resp.role === responsible),
            ) || trivioBooking,
        };
      },
    );
  }

  processDetailEntity(
    event: EventWithValidators,
    competencyOptions?: EventCompetencyOption[],
  ) {
    const nodesAttributeIds = this.getNodeActionsAttributeIds(event.nodes);
    const eventsAttributesWithoutNodeActionsAttributes =
      event.attributes.filter((item) => !nodesAttributeIds[item.id]);

    return {
      ...event,
      id: event.id,
      title: event.event_type.name,
      tags: this.processTags(event),
      company: event.company.name,
      companyTsp: event.company.tsp_url || '',
      companyId: event.company.id,
      childrenEventsCount: event.children_events_count || 0,
      parentEventId: event.parent_event_id || '',
      hashSource: event.company.hash_source,
      createdAt: this.format.toDate(event.created_at),
      employee: this.transform.processEmployee(event),
      documents: event.documents.map((doc) => ({
        ...doc,
        signatures: doc.signatures.map(
          ({ status, signed_by_name, signed_by }) => ({
            status: signStatuses[status] || '',
            signed_by_name: signed_by_name || '',
            signed_by: signed_by || '',
          }),
        ),
      })),
      attributesMeta:
        event.attributes_meta &&
        event.attributes_meta.length &&
        eventsAttributesWithoutNodeActionsAttributes.length
          ? this.transformAttributesMeta.processFormMeta(
              event.attributes_meta,
              eventsAttributesWithoutNodeActionsAttributes,
            )
          : [],
      attributesTop: (event.attributes || []).filter(
        (attribute) =>
          (attribute.type === 'goals_review' ||
            attribute.type === 'goals_final_review' ||
            attribute.type === 'goals' ||
            attribute.type === 'goals_change') &&
          event.nodes.every((node) => {
            return node.actions.every((action) =>
              action.form_attributes.every(
                (formAttribute) => formAttribute.id !== attribute.id,
              ),
            );
          }),
      ),
      attributes: (event.attributes_meta && event.attributes_meta.length
        ? [
            ...this.transformFormMeta.findUnusedAttributes(
              event.attributes_meta,
              event.attributes
                .filter(
                  (item) =>
                    item.type !== 'file' && item.type !== 'file_multiple',
                )
                .filter((item) => !nodesAttributeIds[item.id]),
            ),
            ...event.attributes.filter(
              (item) => item.type === 'file' || item.type === 'file_multiple',
            ),
          ]
        : (event.attributes || []).filter(
            (attribute) =>
              attribute.type !== 'goals' &&
              attribute.type !== 'goals_change' &&
              attribute.type !== 'goals_review' &&
              attribute.type !== 'goals_final_review',
          )
      ).map((item) => ({
        ...item,
        value:
          item.type === 'date'
            ? this.format.toDate(item.value as string)
            : item.value,
      })),
      nodeActions:
        event.nodes &&
        event.nodes.length &&
        event.nodes.map((node) => ({
          ...node,
          deadline: node.deadline
            ? this.transform.processDeadline(node.deadline)
            : '',
          actions: node.actions
            .filter(({ type }) =>
              [
                'year',
                'upload',
                'generate_document_from_template',
                'accept',
                'unep_sign',
                'ukep_sign',
                'pep_sign',
                'decline',
                'decline_sign',
                'declined',
                'declined_sign',
                'competency_eval',
                'competency_profile',
                'unep_sign_batch',
                'ukep_sign_batch',
                'pep_sign_batch',
                'system_booking_trip_ordering',
                'system_booking_trip_limit',
                'system_booking_approve',
                'system_booking_limits_exceeded_approve',
              ].includes(type),
            )
            .map((action) => {
              const validators =
                event.validators && event.validators[node.node_id]
                  ? event.validators[node.node_id]
                  : [];

              const formAttributes = this.transformFormAttributes.process(
                action,
                validators,
                event.company.name,
              );

              const generateDocumentFromTemplateNodes =
                event?.active_nodes?.filter(
                  (activeNodes) =>
                    activeNodes.action.type ===
                    'generate_document_from_template',
                );

              const documentName =
                (generateDocumentFromTemplateNodes.length === 1 &&
                  generateDocumentFromTemplateNodes[0]
                    .document_type_names?.[0]) ||
                '';

              const showFormLayout = [
                'upload',
                'generate_document_from_template',
                'accept',
              ].includes(action.type);

              return {
                ...action,
                initialValues: this.processActionInitialValues(
                  event.attributes,
                  action,
                  action.form_meta && action.form_meta.length
                    ? this.transformFormMeta.getMultipleDocs(
                        action.form_meta,
                        formAttributes,
                      )
                    : [],
                ),
                documentName,
                form_attributes: formAttributes,
                formAttributes:
                  showFormLayout && action.form_meta && action.form_meta.length
                    ? this.transformFormMeta.findUnusedAttributes(
                        action.form_meta,
                        formAttributes,
                      )
                    : formAttributes,
                formMeta:
                  showFormLayout && action.form_meta && action.form_meta.length
                    ? this.transformFormMeta.processFormMeta(
                        action.form_meta,
                        formAttributes,
                      )
                    : [],
                notification: this.processActionNotification(action),
                uploadingMessage: this.processActionUploadingMessage(action),
                isEmployee: action.is_employee,
              };
            }),
        })),
      current_nodes: event.current_nodes.map((node) => ({
        ...node,
        deadline: node.deadline
          ? this.transform.processDeadline(node.deadline)
          : '',
      })),
      deadline: event.deadline ? this.format.toDate(event.deadline) : '',
      cancelActionInfo:
        event.canceled &&
        !event.canceled.reason
          .toLowerCase()
          .includes(t('event.mappers.detail.deadlineText'))
          ? {
              reason: event.canceled.reason,
              comment: event.canceled.comment,
            }
          : null,
      info: this.processDetailInfo(event),
      actions: this.processDetailActions(event),
      vacation: this.transform.processVacation(event),
      businessTrip: this.transform.processBusinessTrip(event),
      comments: this.processCommits(event),
      returnNodeId:
        !!event.nodes &&
        !!event.nodes.length &&
        event.nodes.find(({ actions: nodeAction }) =>
          nodeAction.some(({ type }) => type === 'return'),
        )?.node_id,
      signedAfterCancel: event.signed_after_cancel,
      options: {
        availableVacationDays: event?.options?.available_vacation_days,
      },
      competencyGroupsView: this.processCompetencyGroupsToView(event),
      competencyProfile: this.processCompetencyProfile(
        event,
        competencyOptions,
      ),
    };
  }

  processActionInitialValues = (
    attributes: Attribute[],
    action: NodeAction,
    multipleDocuments: { id: string; attributes: string[] }[],
  ): ActionInitialValues => {
    const formAttributes: Record<string, FormAttribute> = {};
    const documentsAttributes: Record<string, boolean> = {};
    const filledMultipleAttributes: Record<string, Attribute> = {};

    action.form_attributes.forEach(
      (formAttribute) => (formAttributes[formAttribute.id] = formAttribute),
    );

    multipleDocuments.forEach((document) =>
      document.attributes.forEach(
        (attribute) => (documentsAttributes[attribute] = true),
      ),
    );

    attributes
      .filter((attribute) => documentsAttributes[attribute.id])
      .forEach(
        (attribute) => (filledMultipleAttributes[attribute.id] = attribute),
      );

    const isDocumentDateRequired =
      (action.type === 'upload' ||
        action.type === 'generate_document_from_template') &&
      action.document_info_required;

    const result = attributes.reduce(
      (acc: ActionInitialValues, attribute: Attribute) => {
        if (
          !formAttributes[attribute.id] ||
          documentsAttributes[attribute.id]
        ) {
          return acc;
        }

        if (isFileAttribute(attribute)) {
          acc[`_attribute_${attribute.id}`] = {
            value: JSON.stringify({
              type: 'file',
              id: attribute.value.id,
            }),
            source: attribute.value.url,
          };
        }

        if (isFileMultipleAttribute(attribute)) {
          acc[`_attribute_${attribute.id}`] = attribute.value.map(
            (fileAttribute) => ({
              value: JSON.stringify({
                type: 'file',
                id: fileAttribute.id,
              }),
              source: fileAttribute.url,
            }),
          );
        }

        if (isTextAttribute(attribute)) {
          if (attribute.type === 'date') {
            acc[`_attribute_${attribute.id}`] = formatISO(
              new Date(attribute.value),
            );
          }

          if (attribute.type === 'choice' || attribute.type === 'year') {
            const formAttributeChoices =
              formAttributes[attribute.id].options?.choices;
            const formAttributeIsMultiiple =
              !!formAttributes[attribute.id].options?.is_multiple;

            const selectedChoices = formAttributeChoices?.filter(
              ({ label }) => {
                if (Array.isArray(attribute.value)) {
                  return attribute.value.includes(label);
                } else {
                  return label === attribute.value;
                }
              },
            );

            if (selectedChoices?.length) {
              if (Array.isArray(attribute.value)) {
                acc[`_attribute_${attribute.id}`] = formAttributeIsMultiiple
                  ? (selectedChoices.map(
                      (choice) => choice.value,
                    ) as unknown as string[])
                  : ([selectedChoices[0].value] as unknown as string);
              } else {
                acc[`_attribute_${attribute.id}`] = formAttributeIsMultiiple
                  ? [selectedChoices[0].value]
                  : selectedChoices[0].value;
              }
            }
          } else if (attribute.type === 'staff_position') {
            acc[`_attribute_${attribute.id}`] = (attribute.value as any).id;
          } else if (attribute.type === 'goals_review') {
            acc[`_attribute_mandatory_${attribute.id}`] = (
              attribute.value as AttributeValueGoalReview[]
            ).filter(({ goal }) => goal.mandatory);

            acc[`_attribute_optional_${attribute.id}`] = (
              attribute.value as AttributeValueGoalReview[]
            ).filter(({ goal }) => !goal.mandatory);
          } else {
            acc[`_attribute_${attribute.id}`] = attribute.value;
          }
        }

        return acc;
      },
      {},
    );

    multipleDocuments.forEach((document) => {
      if (!document.attributes.length) {
        result[`document_${document.id}`] = [{}];
        return;
      }

      const filledAttribute = document.attributes.find(
        (id) => filledMultipleAttributes[id],
      );

      if (!filledAttribute || !filledMultipleAttributes[filledAttribute]) {
        result[`document_${document.id}`] = [{}];
        return;
      }

      const baseAttribute = filledMultipleAttributes[filledAttribute];

      if (!Array.isArray(baseAttribute.value)) {
        result[`document_${document.id}`] = [{}];
        return;
      }

      result[`document_${document.id}`] = baseAttribute.value.map(
        (attr, index) => {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          const initialValue: Record<string, any> = {};

          return document.attributes.reduce((acc, id) => {
            if (
              filledMultipleAttributes[id] &&
              Array.isArray(filledMultipleAttributes[id].value)
            ) {
              const attribute = filledMultipleAttributes[id];

              if (!formAttributes[attribute.id]) {
                return acc;
              }

              if (
                isMultipleDocumentFileAttribute(attribute) &&
                attribute.value[index]
              ) {
                acc[`_attribute_${attribute.id}`] = {
                  value: JSON.stringify({
                    type: 'file',
                    id: attribute.value[index].id,
                  }),
                  source: attribute.value[index].url,
                };
              }

              if (
                isMultipleDocumentFileMultipleAttribute(attribute) &&
                attribute.value[index]
              ) {
                acc[`_attribute_${attribute.id}`] = attribute.value[index].map(
                  (fileAttribute) => ({
                    value: JSON.stringify({
                      type: 'file',
                      id: fileAttribute.id,
                    }),
                    source: fileAttribute.url,
                  }),
                );
              }

              if (
                isMultipleDocumentTextAttribute(attribute) &&
                attribute.value[index]
              ) {
                if (attribute.type === 'date') {
                  acc[`_attribute_${attribute.id}`] = formatISO(
                    new Date(attribute.value[index]),
                  );
                }

                if (attribute.type === 'choice' || attribute.type === 'year') {
                  const formAttributeChoices =
                    formAttributes[attribute.id].options?.choices;

                  const formAttributeIsMultiiple =
                    !!formAttributes[attribute.id].options?.is_multiple;

                  const selectedChoices = formAttributeChoices?.filter(
                    ({ label }) => {
                      if (Array.isArray(attribute.value[index])) {
                        return attribute.value[index].includes(label);
                      } else {
                        return label === attribute.value[index];
                      }
                    },
                  );

                  if (selectedChoices?.length) {
                    if (Array.isArray(attribute.value[index])) {
                      acc[`_attribute_${attribute.id}`] =
                        formAttributeIsMultiiple
                          ? (selectedChoices.map(
                              (choice) => choice.value,
                            ) as unknown as string[])
                          : ([selectedChoices[0].value] as unknown as string);
                    } else {
                      acc[`_attribute_${attribute.id}`] =
                        formAttributeIsMultiiple
                          ? [selectedChoices[0].value]
                          : selectedChoices[0].value;
                    }
                  }
                } else {
                  acc[`_attribute_${attribute.id}`] = attribute.value[index];
                }
              }
            }

            return acc;
          }, initialValue);
        },
      );
    });

    return {
      ...result,
      ...(isDocumentDateRequired && { documentDate: formatISO(new Date()) }),
    };
  };

  processDetailActions(event: EventDetailEntity) {
    const actions: (
      | 'to_paper'
      | 'print'
      | 'download'
      | 'cancel'
      | 'decline'
      | 'declined'
      | 'decline_sign'
      | 'declined_sign'
      | 'return'
      | 'create_linked'
      | 'change_report'
      | 'multiple_download'
    )[] = [];

    if (
      !!event.nodes &&
      !!event.nodes.length &&
      event.nodes.find(({ actions: nodeAction }) =>
        nodeAction.some(({ type }) => type === 'return'),
      )
    ) {
      actions.push('return');
    }

    const eventDocumentsStatusesArr = [];

    for (const document of event.documents) {
      for (const signature of document.signatures) {
        eventDocumentsStatusesArr.push(signature.status);
      }
    }

    const documentsIsExists = event?.documents && event.documents.length > 0;
    const signedDocumentIsExists = eventDocumentsStatusesArr.includes('signed');

    if (event.permissions.create_linked) {
      actions.push('create_linked');
    }

    if (event.permissions.to_paper) {
      actions.push('to_paper');
    }

    if (documentsIsExists && signedDocumentIsExists) {
      actions.push('download');
    }

    if (event.documents && event.documents.length) {
      actions.push('print');
    }

    if (
      !!event.active_nodes &&
      !!event.active_nodes.length &&
      event.permissions.cancel &&
      event.active_nodes.some(
        ({ action }) =>
          ![
            'completed',
            'system',
            'in_paper',
            'decline',
            'declined',
            'declined_sign',
          ].includes(action.type),
      )
    ) {
      actions.push('cancel');
    }

    if (actions.includes('download')) {
      actions.push('multiple_download');
      const downloadIndex = actions.indexOf('download');
      actions.splice(downloadIndex, 1);
    } else {
      actions.push('change_report');
    }

    return actions;
  }

  processDetailInfo(event: EventDetailEntity) {
    if (!event.canceled) {
      return '';
    } else if (event.canceled.reason_type.startsWith('manual')) {
      return `Отменено ${this.format.toDate(event.canceled.canceled_at)} ${
        event.canceled.reason_type === 'manual_company'
          ? 'представителем компании'
          : t('event.mappers.detail.byEmployeeText')
      }: ${event.canceled.responsible}`;
    } else if (
      event.canceled.reason_type === 'node_deadline' ||
      event.canceled.reason_type === 'event_deadline'
    ) {
      return `Отменено ${this.format.toDate(event.canceled.canceled_at)} ${
        event.canceled.reason
      }`;
    } else if (
      event.canceled.reason_type === 'workflow_change' &&
      event.canceled.comment
    ) {
      return `${event.canceled.comment}`;
    } else if (event.canceled.reason_type === 'dismiss') {
      return `${t(
        'event.mappers.detail.cancelForEmployeeDismissalText',
      )}. Дата увольнения: ${this.format.toDate(
        event.employee?.dismissed_at || '',
      )}`;
    } else {
      return '';
    }
  }

  processCommits(event: EventDetailEntity) {
    if (!event.comments) {
      return null;
    }

    const sortComments = orderBy(
      event.comments,
      [({ created_at }) => new Date(created_at).getTime()],
      ['desc'],
    );

    return sortComments.map(
      ({ comment, user, created_at, role, info, type }) => ({
        createdId: `${created_at}_${user?.id || ''}_${comment.length}`,
        comment,
        role,
        info,
        createdAt: this.format.toDateTime(created_at),
        type,
        user: {
          id: user?.id || '',
          middleName: user?.middle_name || '',
          firstName: user?.first_name || '',
          lastName: user?.last_name || '',
          avatar: `${user?.first_name[0] || ''}${
            user?.last_name[0] || ''
          }`.toLowerCase(),
        },
      }),
    );
  }

  private getNodeActionsAttributeIds(
    nodes: EventNode[],
  ): Record<string, boolean> {
    return nodes.reduce((result, node) => {
      node.actions.forEach((action) => {
        action.form_attributes.forEach((formAttribute) => {
          result = {
            ...result,
            [formAttribute.id]: true,
          };
        });
      });

      return result;
    }, {});
  }

  processActionNotification(action: NodeAction): ActionNotification | null {
    if (
      (action.type !== 'unep_sign' && action.type !== 'unep_sign_batch') ||
      action.unep_type !== 'goskey' ||
      !action.signature_status
    ) {
      return null;
    }

    const batch = action.type === 'unep_sign_batch';

    if (action.signature_status === 'new') {
      return {
        variant: 'primary',
        goskey: true,
        batch,
        message: `Отправьте ${
          batch ? 'документы' : 'документ'
        } на подписание в приложение Госключ.`,
      };
    }

    if (action.signature_status === 'processing') {
      return {
        variant: 'primary',
        goskey: true,
        batch,
        message: `Перейдите в приложение Госключ и подпишите ${
          batch ? 'документы' : 'документ'
        }.`,
      };
    }

    if (action.signature_status === 'canceled') {
      return {
        variant: 'error',
        goskey: false,
        batch,
        message: [
          `Мы получили ваш отказ от подписания ${
            batch ? 'документов' : 'документа'
          } в приложении Госключ.`,
          `Вы можете повторно отправить ${
            batch ? 'документы' : 'документ'
          } на подпись.`,
        ],
      };
    }

    if (action.signature_status === 'error') {
      switch (action.signature_detailed_error) {
        case 'snils_not_found':
          return {
            variant: 'error',
            goskey: true,
            batch,
            message: [
              'Не найдена подтвержденная учетная запись на Госуслугах.',
              [
                {
                  content: 'Чтобы использовать подписание через Госключ, ',
                },
                {
                  href: 'https://www.gosuslugi.ru/help/faq/popular/1',
                  content: 'подтвердите вашу учетную запись',
                },
                { content: '.' },
              ],
            ],
          };
        default:
          return {
            variant: 'error',
            goskey: false,
            batch,
            message: [
              `Ошибка отправки ${
                batch ? 'документов' : 'документа'
              } в приложение Госключ.`,
              'Пожалуйста, попробуйте еще раз.',
            ],
          };
      }
    }

    return null;
  }

  processActionUploadingMessage(action: NodeAction): string | null {
    if (
      !action ||
      (action.type !== 'unep_sign' && action.type !== 'unep_sign_batch') ||
      action.unep_type !== 'goskey' ||
      !action.signature_status
    ) {
      return null;
    }

    const batch = action.type === 'unep_sign_batch';

    if (action.signature_status === 'processing') {
      return `В течение нескольких минут ${
        batch ? 'документы появятся' : 'документ появится'
      } в приложении Госключ`;
    }

    return null;
  }

  processCompetencyGroupsToView(event: EventWithValidators) {
    if (!event.competency_groups) {
      return null;
    }
    return {
      match: this.processFinalCompetencyMatch(event.competency_groups),
      groups: event.competency_groups?.map((item) => {
        return {
          name: item.name,
          competencies: this.processCompetencyToView(item.competencies),
          match: this.processGroupCompetencyMatch(item.competencies),
        };
      }),
    };
  }

  processCompetencyProfile(
    event: EventWithValidators,
    competencyOptions?: EventCompetencyOption[],
  ) {
    const differenceOptions = differenceWith(
      competencyOptions,
      event.competency_groups || [],
      (o1, o2) => o1.competency.group_name === o2.name,
    );
    const groupedByGroupName = groupBy(
      differenceOptions,
      (item) => item.competency.group_name,
    );
    const emptyCompetenciesGroups = Object.entries(groupedByGroupName).map(
      ([groupName, competencies]) => {
        const competenciesWithCurrentValue = competencies.filter(
          (item) => item.current_value,
        );
        return {
          name: groupName,
          competencies: this.processCompetencyToView(
            competenciesWithCurrentValue,
          ),
          profileOptions: competencyOptions?.filter(
            (option) => option.competency.group_name === groupName,
          ),
        };
      },
    );

    return {
      groups: [
        ...(event.competency_groups
          ? event.competency_groups?.map((item) => {
              return {
                name: item.name,
                competencies: this.processCompetencyToView(item.competencies),
                profileOptions: competencyOptions?.filter(
                  (option) => option.competency.group_name === item.name,
                ),
              };
            })
          : []),
        ...emptyCompetenciesGroups,
      ],
    };
  }

  processCompetencyToView(competencies: EventCompetency[]) {
    return competencies.map(
      ({
        values,
        competency,
        scale_values,
        current_value,
        new_value,
        required_level,
      }) => {
        const employeeValue = values?.find((value) => value.is_employee)?.value;
        const companyValue = values?.find((value) => !value.is_employee)?.value;
        const currentValue = current_value;
        const newValue = new_value;
        const requiredLevel = required_level;

        const showProfileValues = currentValue || newValue;

        return {
          id: competency.id,
          name: competency.name,
          description: competency.description,
          competencyProfileChip: showProfileValues
            ? this.processCompetencyProfileChip(currentValue, newValue)
            : null,
          tooltips: scale_values?.map(({ id, name, value, indicators }) => ({
            id,
            name,
            value,
            indicators: indicators || [],
          })),
          currentValue,
          newValue,
          values: [
            {
              label: 'Оценка руководителя:',
              value: companyValue?.value || 0,
              requiredLevel: requiredLevel?.value || 0,
              name: companyValue?.value
                ? `${companyValue.name} ${companyValue.value}/5`
                : 'Не выставлено',
              nameValue: companyValue?.name,
              chipColor: this.processCompetencyChipColor(
                requiredLevel?.value,
                companyValue?.value,
              ),
              type: 'supervisor',
              isShow: !!companyValue || !!employeeValue,
            },
            {
              label: 'Требуемый уровень:',
              value: requiredLevel?.value || 0,
              requiredLevel: requiredLevel?.value || 0,
              name: `${requiredLevel?.name} ${requiredLevel?.value}/5`,
              nameValue: requiredLevel?.name,
              chipColor: 'green' as const,
              type: 'requiredLevel',
              isShow: !!requiredLevel,
            },
            {
              label: t('event.mappers.detail.employeeScoreLabel'),
              value: employeeValue?.value || 0,
              requiredLevel: requiredLevel?.value || 0,
              name: employeeValue?.value
                ? `${employeeValue?.name} ${employeeValue.value}/5`
                : 'Не выставлено',
              nameValue: employeeValue?.name,
              chipColor: this.processCompetencyChipColor(
                requiredLevel?.value,
                employeeValue?.value,
              ),
              type: 'employee',
              isShow: !!employeeValue,
            },
            {
              label: currentValue ? 'Новое значение' : 'Значение',
              value: newValue?.value || 0,
              requiredLevel: newValue?.value || 0,
              name: newValue?.name
                ? `${newValue.name} ${newValue.value}/5`
                : '',
              chipColor: 'green' as const,
              type: 'newValue',
              isShow: newValue && newValue.value !== currentValue?.value,
            },
            {
              label: 'Текущее значение',
              value: currentValue?.value || 0,
              requiredLevel: currentValue?.value || 0,
              name: currentValue?.name
                ? `${currentValue.name} ${currentValue.value}/5`
                : '',
              chipColor: 'green' as const,
              type: 'currentValue',
              isShow: !!currentValue,
              isStroke:
                currentValue &&
                newValue &&
                currentValue.value !== newValue.value,
            },
          ],
        };
      },
    );
  }

  processCompetencyProfileChip(
    currentValue?: CompetencyScaleValue,
    newValue?: CompetencyScaleValue,
  ) {
    if (currentValue && newValue) {
      if (currentValue.value === newValue.value) {
        return {
          name: 'Без изменений',
          chipColor: 'gray' as const,
        };
      } else {
        return {
          name: 'Внесены изменения',
          chipColor: 'orange' as const,
        };
      }
    }
    if (currentValue && !newValue) {
      return {
        name: 'Предлагается удалить',
        chipColor: 'red' as const,
      };
    }
    if (!currentValue && newValue) {
      return {
        name: 'Новая компетенция',
        chipColor: 'blue' as const,
      };
    }
  }

  processGroupCompetencyMatch(competencies: EventCompetency[]) {
    const isHidden = competencies.find(
      (item) => item.current_value || item.new_value,
    );
    const requiredSum = competencies.reduce(
      (partialSum, item) => partialSum + (item.required_level?.value || 0),
      0,
    );
    const factSum = competencies.reduce((partialSum, item) => {
      const companyValue = item.values?.find((value) => !value?.is_employee)
        ?.value?.value;
      if (!companyValue) {
        return 0;
      }
      return partialSum + companyValue;
    }, 0);

    return {
      requiredSum,
      factSum,
      isHidden,
      color: this.processCompetencyChipColor(requiredSum, factSum),
      label: this.processCompetencyMatchLabel(requiredSum, factSum),
      description:
        'Расчёт числового значения соответствия по группе происходит после заполнения оценок для всех компетенций в группе',
    };
  }

  processFinalCompetencyMatch(competencies?: EventCompetencyGroup[]) {
    const isHidden = competencies?.find((item) =>
      item.competencies.find(
        (competencyItem) =>
          competencyItem.current_value || competencyItem.new_value,
      ),
    );
    const requiredSum =
      competencies?.reduce(
        (partialSum, item) =>
          partialSum +
          this.processGroupCompetencyMatch(item.competencies).requiredSum,
        0,
      ) || 0;
    const factSum =
      competencies?.reduce(
        (partialSum, item) =>
          partialSum +
          this.processGroupCompetencyMatch(item.competencies).factSum,
        0,
      ) || 0;
    return {
      result:
        factSum === 0
          ? 'Не выставлено'
          : // eslint-disable-next-line no-magic-numbers
            `${Math.round((factSum / requiredSum) * 100)} %`,
      description:
        'Итоговое соответствие — процентное соотношение результатов всех соответствий по группам компетенций',
      color: 'gray' as const,
      isHidden,
    };
  }

  processCompetencyChipColor(
    targetLevel?: number,
    level?: number,
  ): ChipColorUnion {
    if (!level || !targetLevel) {
      return 'gray';
    }
    if (level === targetLevel) {
      return 'green';
    }
    if (level < targetLevel) {
      return 'red';
    }
    if (level > targetLevel) {
      return 'blue';
    }
    return 'gray';
  }

  processCompetencyMatchLabel(targetLevel: number, level?: number) {
    if (!level || !targetLevel) {
      return 'Не выставлено';
    }
    if (level === targetLevel) {
      return 'Соответствует';
    }
    if (level < targetLevel) {
      return 'Не соответствует';
    }
    if (level > targetLevel) {
      return 'Превышает';
    }
    return 'Не выставлено';
  }
}
