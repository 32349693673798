import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { Chip } from '@material-ui/core';

import { Box } from '../Box';
import { Counter } from '../Counter';
import { Typography } from '../Typography';

import { ChipColor, ChipColorUnion, ChipSize } from './ColoredChip.types';
import { useStyles } from './ColoredChip.styles';

export type ColoredChipProps = {
  label: string | ReactNode;
  color?: ChipColorUnion;
  size?: keyof typeof ChipSize;
  fullWidth?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  counter?: number | string;
  counterSticked?: boolean;
  signed?: boolean;
  onClick?: () => void;
};

export const isChipColor = (str: unknown): str is ChipColor =>
  typeof str === 'string' && Object.values<string>(ChipColor).includes(str);

export const ColoredChip = ({
  label,
  fullWidth = false,
  startIcon,
  endIcon,
  counter,
  size = 'small',
  color = ChipColor.gray,
  disabled = false,
  counterSticked = false,
  signed = false,
  onClick,
  ...rest
}: ColoredChipProps) => {
  const classes = useStyles({
    size: ChipSize[size],
    fullWidth,
    color: isChipColor(color) ? ChipColor[color] : ChipColor.gray,
    disabled,
  });

  const resultLabel = (
    <div className={classNames(classes.chipLabel, 'aqa_chip_label')}>
      {startIcon && (
        <Box
          className={classNames(
            classes.icon,
            classes.startIcon,
            'aqa_chip_start_icon',
          )}
        >
          {startIcon}
        </Box>
      )}
      <Typography
        className={classes.chipLabelTypography}
        variant={size === 'extraSmall' ? 'caption' : 'body3'}
      >
        {label}
      </Typography>
      {endIcon && (
        <Box
          className={classNames(
            classes.icon,
            classes.endIcon,
            'aqa_chip_end_icon',
          )}
        >
          {endIcon}
        </Box>
      )}
      {counter !== undefined && !counterSticked && (
        <Counter
          className={classNames('aqa_chip_counter', classes.counter)}
          counter={counter}
          size={size}
          signed={signed}
        />
      )}
    </div>
  );

  return (
    <div className={classes.container}>
      <Chip
        {...rest}
        className={classNames(
          classes.chip,
          'aqa_chip',
          ...(onClick ? [classes.linkChip] : []),
        )}
        label={resultLabel}
        onClick={onClick}
      />
      {counter != undefined && counterSticked && (
        <Counter
          className={classNames('aqa_chip_counter', classes.counterSticked)}
          counter={counter}
          size={size}
          signed={signed}
        />
      )}
    </div>
  );
};
