import React, { useCallback } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Button } from '@vk-hr-tek/ui/Button';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useInject } from '@vk-hr-tek/core/ioc';
import { UserRouter } from '@vk-hr-tek/app/user/types';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';

import { UnitSectionLabel } from './UnitSectionLabel';

interface CompanyUnitProps {
  employeeId: string;
  companyId: string;
  companyUnit: {
    unit: {
      id: string;
      name: string;
    };
    allSetting?: {
      id: string;
      name: string;
    }[];
    directSetting?: {
      id: string;
      name: string;
    }[];
    roleInUnit: {
      label: string;
      role: 'manager' | 'assistant';
    };
    neverSetting?: {
      id: string;
      name: string;
    }[];
  };
}

export const CompanyUnit = ({
  employeeId,
  companyId,
  companyUnit: { unit, allSetting, directSetting, roleInUnit, neverSetting },
}: CompanyUnitProps) => {
  const isDesktop = useIsDesktop();

  const router = useInject<UserRouter>(UserRouter);

  const redirectToApprovalSettings = useCallback(() => {
    router.goToProfileApprovalSettings({
      employeeId,
      companyId,
      unitId: unit.id,
    });
  }, [router, companyId, employeeId, unit.id]);

  return (
    <Box mt="16">
      <Paper>
        <Box p="20">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              gap="12"
              justifyContent={isDesktop ? '' : 'space-between'}
              width="100%"
            >
              <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>
                {unit.name}
              </Typography>
              <Box alignSelf={isDesktop ? '' : 'flex-start'}>
                <ColoredChip
                  label={roleInUnit.label}
                  color="gray"
                  size="extraSmall"
                />
              </Box>
            </Box>
            {isDesktop && roleInUnit.role === 'manager' && (
              <Button
                onClick={redirectToApprovalSettings}
                variant="primary"
                size="small"
              >
                Редактировать
              </Button>
            )}
          </Box>
          <Box mt="16" display="flex" gap="12" flexWrap="wrap">
            {!!allSetting?.length && (
              <UnitSectionLabel
                settingList={allSetting}
                label="Согласую всех"
                chipColor="green"
              />
            )}
            {!!directSetting?.length && (
              <UnitSectionLabel
                settingList={directSetting}
                label="Только прямых"
                chipColor="purple"
              />
            )}
            {!!neverSetting?.length && (
              <UnitSectionLabel
                settingList={neverSetting}
                label="Не согласую никого"
                chipColor="darkgray"
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
