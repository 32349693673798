import { injectable } from 'inversify';

import { FormatService } from '@vk-hr-tek/core/format';
import { BoxColors } from '@vk-hr-tek/ui/Box';
import { ChipColorUnion } from '@vk-hr-tek/ui/ColoredChip';
import { Logger } from '@vk-hr-tek/core/logger';
import { Calendar } from '@vk-hr-tek/core/calendar';

import {
  EventListItem as EventListEntity,
  Event as EventDetailEntity,
  Action,
} from '@app/gen/events';

import {
  BUSINESS_TRIP_END_ATTR_ID,
  BUSINESS_TRIP_START_ATTR_ID,
  COMPENSATION_ID,
  FIFTH_VACATION_TYPE_ATTR_ID,
  FIRST_VACATION_TYPE_ATTR_ID,
  FOURTH_VACATION_TYPE_ATTR_ID,
  SECOND_VACATION_TYPE_ATTR_ID,
  THIRD_VACATION_TYPE_ATTR_ID,
  VACATION_END_ATTR_ID,
  VACATION_PLACE_ID,
  VACATION_START_ATTR_ID,
  VACATION_TYPE_ATTR_ID,
} from '../events.constants';

import { EventsMapper } from './events.mapper';

export type EventUnitData = {
  label: string;
  mobileLabel: string;
  value: string;
  color: ChipColorUnion;
  width: '50%' | '100%';
};

const colors: Record<string, ChipColorUnion> = {
  completed: 'green',
  system: 'gray',
  in_paper: 'gray',
  upload: 'orange',
  accept: 'purple',
  unep_sign: 'blue',
  unep_sign_batch: 'blue',
  ukep_sign: 'blue',
  ukep_sign_batch: 'blue',
  pep_sign: 'blue',
  pep_sign_batch: 'blue',
  default: 'gray',
  canceled: 'red',
  generate_document_from_template: 'orange',
  declined: 'red',
  declined_sign: 'red',
  system_booking_hook: 'gray',
  system_booking_approve: 'purple',
  system_booking_limits_exceeded_approve: 'purple',
  system_booking_trip_create: 'gray',
  system_booking_trip_ordering: 'blue',
  system_booking_trip_limit: 'blue',
  system_booking_trip_limit_approved: 'gray',
};

@injectable()
export class EventsListMapper {
  constructor(
    private format: FormatService,
    private transform: EventsMapper,
    private logger: Logger,
    private calendar: Calendar,
  ) {}

  processListEntity(event: EventListEntity) {
    const stage = this.transform.processStage(event);
    const daysOnNode = event?.days_on_node;
    const activeStages = this.transform.processActiveStages(event);

    return {
      id: event.id,
      stage: {
        ...stage,
        count: event.active_nodes.length,
        ...(daysOnNode
          ? {
              daysOnNode,
            }
          : {}),
        ...(activeStages ? { activeStages } : {}),
      },
      side: {
        name: event.active_nodes
          .map(({ responsible }) => responsible)
          .filter((responsible) => responsible)
          .join(', '),
        count: event.active_nodes.length,
        ...(activeStages ? { activeStages } : {}),
      },
      eventType: {
        name: event.event_type.name,
        vacation: this.transform.processVacation(event),
        businessTrip: this.transform.processBusinessTrip(event),
      },
      documents: {
        items: event.documents
          .map(({ document_type_name }) => document_type_name)
          .join(', '),
        count: event.documents.length,
      },
      documentsToPrint: event.documents.map(({ id }) => id),
      company: event.company.name,
      companyTsp: event.company.tsp_url || '',
      createdAt: this.format.toDate(event.created_at),
      employee: this.transform.processEmployee(event),
      deadline: event.deadline &&
        !(stage?.name === 'Отменено' || stage?.name === 'Завершено') && {
          date: this.transform.processDeadline(event.deadline),
          color: 'text.light.primary' as BoxColors,
        },
      canSign: !!(
        event.permissions &&
        event.permissions.company_sign &&
        event.permissions.company_sign.length
      ),
      nodesToSign: event.permissions && event.permissions.company_sign,
      vacation: this.transform.processVacation(event),
    };
  }

  findFormRoleFields(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValuesObj: Record<string, any>,
    getFullFieldName?: boolean,
  ) {
    return Object.keys(formValuesObj)
      .map((key) => {
        const regexpResultArray = key.match(/^roles_([0-9a-zA-Z-]+)$/);

        if (regexpResultArray) {
          return getFullFieldName ? regexpResultArray[0] : regexpResultArray[1];
        }

        return null;
      })
      .filter((roleId) => roleId);
  }

  gatherAssignedRoles(formValuesObj: Record<string, any>) {
    return this.findFormRoleFields(formValuesObj).reduce(
      (acc: { role_id: string; group_id: string }[], roleId) => {
        return !roleId
          ? acc
          : [
              ...acc,
              { role_id: roleId, group_id: formValuesObj[`roles_${roleId}`] },
            ];
      },
      [],
    );
  }

  gatherAssignedRolesForBatch(formValuesObj: Record<string, any>) {
    return this.findFormRoleFields(formValuesObj).reduce(
      (acc: string[], roleId) => {
        return !roleId
          ? acc
          : [...acc, `${roleId}:${formValuesObj[`roles_${roleId}`]}`];
      },
      [],
    );
  }

  processAbsencesEvent(event: EventListEntity | EventDetailEntity) {
    if (event.employee) {
      const units: EventUnitData[] = [];

      if (event.employee.unit) {
        units.push({
          label: 'Управленческая',
          mobileLabel: 'Упр. структура',
          value: event.employee.unit,
          color: 'blue',
          width: '50%',
        });
      }

      if (event.employee.legal_unit) {
        units.push({
          label: 'Юридическая',
          mobileLabel: 'Юр. структура',
          value: event.employee.legal_unit,
          color: 'purple',
          width: '50%',
        });
      }

      if (units.length === 1) {
        units[0].width = '100%';
      }

      return {
        id: event.employee.id,
        name: `${event.employee.second_name} ${event.employee.first_name}`,
        fullName: `${event.employee.second_name} ${event.employee.first_name} ${event.employee.middle_name}`,
        avatar: `${event.employee.second_name[0]}${event.employee.first_name[0]}`,
        personnelNumber: event.employee.personnel_number,
        birthday: this.format.toDate(event.employee.birthday),
        dismissed:
          !!event.employee.dismissed_at &&
          Date.now() > +new Date(event.employee.dismissed_at),
        dismissedAt: event.employee.dismissed_at
          ? this.format.toDate(event.employee.dismissed_at)
          : null,
        phone: event.employee.phone ?? '-',
        email: event.employee.email ?? '-',
        units: units,
      };
    }
  }

  processStageName(stage: Action) {
    try {
      switch (stage.type) {
        case 'system_booking_hook':
          return 'Обработка';
        case 'system_booking_approve':
          return 'Согласование';
        case 'system_booking_limits_exceeded_approve':
          return 'Согласование';
        case 'system_booking_trip_create':
          return 'Обработка';
        case 'system_booking_trip_ordering':
          return 'Бронирование';
        case 'system_booking_trip_limit':
          return 'Бронирование';
        case 'system_booking_trip_limit_approved':
          return 'Обработка';
        default:
          return stage.name === 'Отказ от подписания' ? 'Отказано' : stage.name;
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  processStage(event: EventListEntity | EventDetailEntity) {
    try {
      if (!event.active_nodes || !event.active_nodes.length) {
        return;
      }

      const stage = event.active_nodes[0].action;

      let label = '';

      if (stage.type === 'upload') {
        label = 'Загрузить до';
      } else if (stage.type === 'accept') {
        label = 'Проверить до';
      } else if (
        stage.type === 'decline_sign' ||
        stage.type === 'declined_sign'
      ) {
        label = 'Отказано';
      } else if (
        stage.type === 'ukep_sign' ||
        stage.type === 'unep_sign' ||
        stage.type === 'ukep_sign_batch' ||
        stage.type === 'unep_sign_batch' ||
        stage.type === 'pep_sign' ||
        stage.type === 'pep_sign_batch'
      ) {
        label = 'Подписать до';
      } else if (stage.type === 'generate_document_from_template') {
        label = 'Создать до';
      }

      return {
        name: this.processStageName(stage),
        color: colors[stage.type] || colors.default,
        label,
      };
    } catch (e) {
      this.logger.error(e);
    }
  }

  processActiveStages(event: EventListEntity | EventDetailEntity) {
    if (!event.active_nodes || event.active_nodes.length === 0) {
      return;
    }

    return event.active_nodes.map(({ action, responsible }) => ({
      actionName: action.name,
      responsible,
    }));
  }

  processAbsencesListEntity(event: EventListEntity) {
    const stage = this.processStage(event);
    const daysOnNode = event?.days_on_node;
    const activeStages = this.processActiveStages(event);

    return {
      id: event.id,
      stage: {
        ...stage,
        count: event.active_nodes.length,
        ...(daysOnNode
          ? {
              daysOnNode,
            }
          : {}),
        ...(activeStages ? { activeStages } : {}),
      },
      side: {
        name: event.active_nodes
          .map(({ responsible }) => responsible)
          .filter((responsible) => responsible)
          .join(', '),
        count: event.active_nodes.length,
        ...(activeStages ? { activeStages } : {}),
      },
      eventType: {
        name: event.event_type.name,
        vacation: this.processVacation(event),
        businessTrip: this.processBusinessTrip(event),
      },
      documents: {
        items: event.documents
          .map(({ document_type_name }) => document_type_name)
          .join(', '),
        count: event.documents.length,
        compensation: event.attributes.find(
          (attr) => attr.id === COMPENSATION_ID,
        )?.value,
      },
      documentsToPrint: event.documents.map(({ id }) => id),
      company: this.processCompany(event),
      companyTsp: event.company.tsp_url || '',
      createdAt: this.format.toDate(event.created_at),
      employee: this.processAbsencesEvent(event),
      deadline: event.deadline &&
        !(stage?.name === 'Отменено' || stage?.name === 'Завершено') && {
          data: this.processDeadline(event.deadline),
          color: 'text.light.primary' as BoxColors,
        },
      canSign: !!(
        event.permissions &&
        event.permissions.company_sign &&
        event.permissions.company_sign.length
      ),
      nodesToSign: event.permissions && event.permissions.company_sign,
      vacation: this.processVacation(event),
      place: this.processPlace(event),
      report: this.processReport(event),
      absences: this.processAbsencesType(event),
    };
  }

  processCompany(event: EventListEntity | EventDetailEntity) {
    const compensationAttr = event.attributes.find(
      (attr) => attr.id === COMPENSATION_ID,
    );

    return {
      document: event.company.name,
      compensation: compensationAttr?.value,
    };
  }

  processAbsencesType(event: EventListEntity | EventDetailEntity) {
    const absenceAttr = event.attributes
      .filter((attr) => {
        return [
          VACATION_TYPE_ATTR_ID,
          FIRST_VACATION_TYPE_ATTR_ID,
          SECOND_VACATION_TYPE_ATTR_ID,
          THIRD_VACATION_TYPE_ATTR_ID,
          FOURTH_VACATION_TYPE_ATTR_ID,
          FIFTH_VACATION_TYPE_ATTR_ID,
        ].includes(attr.id);
      })
      .map((item) => item.value);

    return {
      kind: event.event_type.name,
      type: absenceAttr,
    };
  }

  processReport(event: EventListEntity | EventDetailEntity) {
    return (event as EventListEntity).report_status;
  }

  processPlace(event: EventListEntity | EventDetailEntity) {
    const placeAttr = event.attributes.find(
      (attr) => attr.id === VACATION_PLACE_ID,
    );

    return {
      country: placeAttr?.value,
      city: placeAttr?.description,
    };
  }

  processVacation(event: EventListEntity | EventDetailEntity) {
    const start = event.attributes.find(
      (attr) => attr.id === VACATION_START_ATTR_ID,
    );
    const end = event.attributes.find(
      (attr) => attr.id === VACATION_END_ATTR_ID,
    );

    const startDate = start ? new Date(start.value as string) : null;
    const endDate = end ? new Date(end.value as string) : null;
    let count: string | number = '';

    if (startDate && endDate) {
      count =
        startDate <= endDate
          ? this.calendar.countOfVacationDays(endDate, startDate)
          : 'Ошибка';
    }

    return {
      start: start ? this.format.toDate(start.value as string) : '',
      end: end ? this.format.toDate(end.value as string) : '',
      count,
      isHolidaysInVacationInterval: Boolean(
        startDate &&
          endDate &&
          startDate <= endDate &&
          this.calendar.countOfHolidaysInInterval(startDate, endDate),
      ),
    };
  }

  processBusinessTrip(event: EventListEntity | EventDetailEntity) {
    const start = event.attributes.find(
      (attr) => attr.id === BUSINESS_TRIP_START_ATTR_ID,
    );
    const end = event.attributes.find(
      (attr) => attr.id === BUSINESS_TRIP_END_ATTR_ID,
    );

    return {
      start: start ? this.format.toDate(start.value as string) : '',
      end: end ? this.format.toDate(end.value as string) : '',
    };
  }

  processDeadline(deadline: string) {
    const durationDate = this.calendar.formatISO(
      this.calendar.subMinutes(new Date(deadline), 1),
    );

    return {
      time: this.format.toTime(durationDate),
      date: this.format.toDate(durationDate),
    };
  }
}
